<!--能人列表-->
<template>
    <div class="bestPerson">
        <div class="breadcrumb w-1400">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/sanxiangxueyuan'}">三农学院</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/school'}">专题讲座</el-breadcrumb-item>
                <el-breadcrumb-item >专题讲座列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="p-list w-1400">
            <div class="row sort-title">
                <div :class="{act:actmenu ==1}" @click="actList = classify;actmenu=1">按内容分类</div>|
                <div :class="{act:actmenu ==2}" @click="actList = timeList;actmenu=2">按时间分类 </div>
            </div>
            <div class="shanxue clearfix">
                <div class="sx-h5 fl">筛选：</div>
                <ul class="fl sx-list chearfix">
                    <li class="fl sx-item" :class="{act:item == '全部'}" v-for="item in actList" :key="item">{{ item}}</li>
                </ul>
            </div>
            <div class="c-content">
                <el-row :gutter="40">
                    <el-col :span="8" v-for="item in 9" :key="item + 'aa'">
                        <router-link to="/chairDetail">
                            <div class="c-item">
                                <div class="c-pic">
                                    <el-image style="width:100%;height:100%" :src="require('../../../../public/img/sxxy/chair-list1.png')"></el-image>
                                </div>
                                <div class="c-name">农户牦牛养殖技术</div>
                                <div class="c-info">
                                    <div>观看人数 996</div>
                                    <div>共4集</div>
                                </div>
                            </div>
                        </router-link>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div style="height:200px"></div>
    </div>
</template>
<script>
import thirdNav from '@/components/thirdNav'
export default {
    components:{
        thirdNav,
    },
    data(){
        return{
            actList:[],
            actmenu:1,
            classify:[
                '全部','家庭农场生产经营','粮食作物生产经营','园艺作物生产经营','畜禽养殖经营','动物疫病防治','农民合作社运营管理','农业企业经营管理','农村综合管理','农村财务会计' ,'休闲农业','农业机械使用与维修','农村信息技术与应用','农牧业实用新技术',
            ],
            timeList:['全部','一周内','一月内','三月内','半年内','一年以上',]
        }
    },
    mounted(){
        this.actList = this.classify;
    },
    methods:{
    }
}
</script>
<style scoped>
.c-info{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
    font-weight: 500;
}
.c-item:hover{
    color: #00a0e9;
}
.c-item{
    font-size: 18px;
    font-weight: 600;
    color: #666;
    line-height: 30px;
    margin-top: 20px;
}
.c-pic{
    height: 300px;
}
.sx-item.act{
    font-weight:600;
    color: #00a0e9;
    cursor: pointer;
}
.sx-item:hover{
    cursor: pointer;
    color: #00a0e9;
}
.sx-item{
    margin: 0 20px;
    padding:0 10px
}
.sx-list{
    width: calc(100% - 80px);
    line-height: 40px;
    font-size: 18px;
}
.sx-h5{
    width: 80px;
    text-align: right;
    line-height: 40px;
    font-size: 18px;
    font-weight: 600;
}
.sort-title>div.act{
    color: #00a0e9;
}
.sort-title>div{
    margin: 0 20px;
    cursor: pointer;
}
.sort-title>div:first-child{
    margin-left: 0;
}
.sort-title{
    line-height: 48px;
    font-size: 22px;
    font-weight: 600;
    border: 1px solid #777;
    padding-left: 12px;
}
.picbox{
    position: absolute;
    top: -220px;
    left: 0;
    width: 240px;
    height: 220px;
    background: url(../../../../public/img/sxxy/menu2.png) no-repeat center center;
    border: 3px solid #f89807;
}
.submenu-item:nth-child(1){background: #dc5238;}
.submenu-item:nth-child(2){background: #f89807;}
.submenu-item:nth-child(3){background: #1db59b;}
.submenu-item{
    width: 240px;
    text-align: center;
    color: #fff;
    position: relative;
}
.submenu{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    line-height: 50px;
    background: #00a0e9;
    font-size: 22px;
    justify-content: center;
}
.banner{
    height: 630px;
    background: url(../../../../public/img/sxxy/banner1.png) no-repeat center center;
}
.bestPerson header{
    position: relative;
    height: 630px;
}
.bestPerson{
    font-family: '微软雅黑';
}
.breadcrumb >>> .el-breadcrumb .is-link,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner{
    font-size: 18px;
    font-family: '微软雅黑';
    color: #0e6ac1;
}
.breadcrumb{
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #0e6ac1;
    margin-bottom: 50px;
}
</style>